
export default {
  name: 'MainAppBar',

  props: {
    hasSecondary: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'ADRA Office Manager',
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
  },
}

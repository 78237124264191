import { getField, updateField } from 'vuex-map-fields'
import Vue from 'vue'
export const state = () => ({
  users: [],
  selectedUsers: [],
})
export const getters = {
  getField,
  hasSelectedUsers(state) {
    return state.selectedUsers.length > 0
  },
}
export const mutations = {
  updateField,
  UPDATE_SELECTED_USER: (state, payload) => {
    const index = state.selectedUsers.findIndex((u) => u.id === payload.id)
    Vue.set(state.selectedUsers, index, payload)
  },
}

export const actions = {
  // updateUser({ commit, state }, payload) {
  //   commit('UPDATE_SELECTED_USER', user)
  // },
}

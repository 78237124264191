export const state = () => ({
  errors: [],
})

export const mutations = {
  SET_ERRORS(state, errors) {
    state.errors = errors
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
}
export const getters = {
  hasErrors(state) {
    return state.errors.length > 0
  },
  groupedErrors(state) {
    return Object.entries(state.errors).reduce((carry, [key, value]) => {
      const row = Number(key.split('.')[1] || 0) + 2
      // row++
      return {
        ...carry,
        [row]: [...(carry[row] || []), ...value],
      }
    }, {})
  },
}
export const actions = {
  setErrors({ commit, $store, state }, payload) {
    console.log(
      '🚀 ~ file: vee-validate.js ~ line 3 ~ setErrors ~ payload',
      payload
    )

    const { error, observer } = payload

    const errors = error.response.data.errors[0].meta
    commit('SET_ERRORS', errors)
    console.log(
      '🚀 ~ file: vee-validate.js ~ line 14 ~ setErrors ~ errors',
      errors
    )
    Object.keys(errors).forEach((error) => {
      // below we get rid of data. to avoid replacing all the
      // name attributes with data.name
      observer.setErrors({
        [error.replace('data.', '')]: [errors[error].join(' ')],
        // [error]: [errors[error].join(' ')],
      })
    })
  },
}

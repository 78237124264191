export const state = () => ({
  status: 'idle',
})

export const mutations = {
  SET_STATUS(state, status) {
    state.status = status
  },
}

export const actions = {
  setStatus({ commit }, status) {
    commit('SET_STATUS', status)
  },
}

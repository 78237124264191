export const state = () => ({
  list: [],
  currentSystem: null,
})
export const getters = {
  getById: (state) => (id) => {
    return state.list.find((sys) => sys.id === id)
  },
}
export const mutations = {
  UPSERT(state, system) {
    let list = state.list
    const index = list.findIndex((sys) => sys.id === system.id)
    if (index === -1) {
      list = [...list, system]
    } else {
      list[index] = system
    }
    state.list = list
  },
}

export const actions = {
  async get({ commit }, id) {
    const res = await this.$axios.get(`systems/${id}`)
    commit('UPSERT', res.data.data)
    return res
  },
  async index({ commit }, params = {}) {
    return await this.$axios.get(`systems`, {
      params,
    })
  },
  async store({ commit }, payload) {
    const res = await this.$axios.post(`systems`, {
      data: payload,
    })
    console.log('🚀 ~ file: systems.js ~ line 19 ~ store ~ res', res)
    return res
  },
  async update({ commit }, system) {
    const res = await this.$axios.patch(`systems/${system.id}`, {
      data: system,
    })
    return res
  },
}

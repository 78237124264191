
export default {
  name: 'ErrorPage',
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
    }
  },
  computed: {
    title() {
      return this.error.title || 'Oops...'
    },
    message() {
      let message = 'An error occurred'
      switch (this.error.statusCode) {
        case 404:
          message = 'Page not found!'
          break

        default:
          break
      }
      message = this.error?.message || message
      return message
    },
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
}


export default {
  name: 'AdraLanguageSelector',
  data() {
    return {
      overlay: false,
      active: false,
      search: null,
    }
  },

  computed: {
    availableLocales() {
      const locales = this.$i18n.locales.filter(
        (i) => i.code !== this.$i18n.locale
      )
      if (this.search) {
        return locales.filter((i) => {
          const search = this.search.toLowerCase()
          return (
            i.nativeName.toLowerCase().includes(search) ||
            i.name.toLowerCase().includes(search)
          )
        })
      }
      return locales
    },
  },
  methods: {
    toggleSelect() {
      this.active = true
      this.$refs.autocomplete.isMenuActive = true // open item list
      this.$refs.autocomplete.activateMenu()
    },
    switchLanguage(languageCode = null) {
      this.overlay = true

      if (!languageCode) {
        languageCode = this.$i18n.locale
      }
      this.$i18n.setLocale(languageCode)
      this.$vuetify.lang.current = languageCode
      setTimeout(() => {
        this.overlay = false
      }, 500)
      this.active = false
      this.search = null
    },
  },
}

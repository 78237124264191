export default function ({ $axios, redirect, store, app }) {
  $axios.onRequest((config) => {})

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 422) {
      store.dispatch(
        'notifications/danger',
        'Some fields require your attention'
      )
    } else if (code === 403) {
      store.dispatch('notifications/danger', 'Unauthorized')
    } else if (code === 500) {
      store.dispatch(
        'notifications/danger',
        error?.response?.data?.message || 'An error occurred'
      )
    } else {
      store.dispatch(
        'notifications/danger',
        'Something went wrong, email support@adra.cloud for help'
      )
    }
  })
}

export const state = () => ({})

export const mutations = {}

export const actions = {
  async index({ commit }, id) {
    const res = await this.$axios.get(`systems/${id}/organizations`)
    return res
  },
  async store({ commit }, { id, organizations }) {
    const res = await this.$axios.post(`systems/${id}/organizations`, {
      data: organizations,
    })
    return res
  },
  /*eslint-disable*/
  async update({ commit }, { organizationId, systemId, payload }) {
    console.log(
      '🚀 ~ file: systems-organizations.js ~ line 18 ~ update ~ organizationId, systemId, ...payload',
      organizationId,
      systemId,
      payload
    )
    const res = await this.$axios.patch(
      `systems/${organizationId}/organizations/${systemId}`,
      { data: payload }
    )
    return res
  },
  async destroy({ commit }, { organizationId, systemId }) {
    console.log('\x1b[32;1m%s\x1b[0m  ', '=> systemId', systemId)
    const res = await this.$axios.delete(
      `systems/${organizationId}/organizations/${systemId}`
    )
    return res
  },

  async getsystemsBySystem({ commit }, params) {
    const resp = await this.$axios.get(
      `organizations/${params.storeParams.id}/systems`,
      {
        params: params.params,
      }
    )

    return resp
  },
  async getOrganization({ commit }, params) {
    const resp = await this.$axios.get(
      `organizations/${params.id}/systems/${params.org_id}`
    )
    return resp
  },
}


export default {
  computed: {
    showBreadCrumbs() {
      return this.$route.name !== 'index'
    },
    hasSecondary() {
      const categories = ['dashboard']
      return categories.some((c) => this.$route.path.startsWith(`/${c}`))
    },
    category() {
      return this.$route.path.split('/')[1]
    },
    itemHeader() {
      const headers = {
        dashboard: {
          id: '1',
          icon: 'mdi-view-dashboard',
          title: this.$t('dashboard'),
          to: '/dashboard',
        },
      }
      return headers[this.category] || {}
    },
    items() {
      const items = {
        dashboard: [
          {
            id: '3',
            title: 'Your organizations',
            icon: 'mdi-office-building',
            to: '/dashboard/organizations',
          },
          {
            id: '4',
            title: 'Your systems',
            to: '/dashboard/systems',
            icon: 'mdi-desktop-mac-dashboard',

            disabled: true,
          },
          {
            id: '5',
            title: 'Your invites',
            icon: 'mdi-account-plus',

            to: '/dashboard/invites',
            disabled: true,
          },
        ],
        users: [],
      }
      return items[this.category]
    },
  },
}

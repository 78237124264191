
export default {
  name: 'AdraBreadcrumbs',
  computed: {
    crumbs() {
      const fullPath = this.$route.fullPath
      const params = fullPath.startsWith('/')
        ? fullPath.substring(1).split('/')
        : fullPath.split('/')
      const crumbs = [{ title: 'Home', path: '/', icon: 'mdi-home' }]
      let path = ''

      params.forEach((param, index) => {
        path = `${path}/${param}`
        if (index === 1) {
          switch (params[0]) {
            case 'organizations':
              param =
                this.$store.getters['organizations/getById'](param).attributes
                  .name
              break
            case 'systems':
              param =
                this.$store.getters['systems/getById'](param).attributes.name
              break
            default:
              break
          }
        }
        const match = this.$router.match(path)
        if (match.name !== null) {
          crumbs.push({
            title: param,
            isLast: index === params.length - 1,
            ...match,
          })
        }
      })
      return crumbs
    },
  },
}

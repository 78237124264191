
export default {
  name: 'MainNavbar',
  computed: {
    items() {
      let baseItems = []
      if (!this.$auth.loggedIn) {
        return baseItems
      }
      if (this.$auth.loggedIn) {
        baseItems = [
          ...baseItems,
          {
            title: 'Organizations',
            action: 'mdi-office-building',
            active: true,
            items: [{ title: 'Organizations Directory', to: '/organizations' }],
          },
          {
            title: 'Users',
            action: 'mdi-account-box',
            active: false,
            items: [{ title: 'User Directory', to: '/users' }],
          },
        ]
      }
      if (this.$user.isSuperadmin) {
        baseItems = [
          ...baseItems,
          {
            title: 'Systems',
            action: 'mdi-desktop-mac-dashboard',
            active: false,
            items: [{ title: 'Systems Directory', to: '/systems' }],
          },
          {
            title: 'Admin',
            action: 'mdi-desktop-mac-dashboard',
            active: false,
            items: [
              // { title: 'Admin ', to: '/admin' },
              { title: 'AAC Onboard ', to: '/admin/onboard' },
              { title: 'Users List', to: '/admin/users/list' },
            ],
          },
        ]
      }
      return baseItems
    },
  },
}


export default {
  name: 'LoginButton',
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    logout() {
      this.$auth.logout()
    },
    login() {
      this.$auth.loginWith(this.$config.AUTH_PROVIDER)
    },
  },
}

export const state = () => ({
  items: [{ name: 'Home', path: '/', disabled: false, icon: 'mdi-home' }],
})

export const mutations = {
  SET_ITEMS(state, items) {
    const base = [
      { name: 'Home', path: '/', disabled: false, icon: 'mdi-home' },
    ]
    state.items = [...base, ...items]
  },
}

export const state = () => ({})

export const mutations = {}

export const actions = {
  // async get({ commit }, id) {
  //   const {
  //     data: { data: organization },
  //   } = await this.$axios.get(`organizations/${id}`)
  //   commit('SET_CURRENT', organization)
  // },
  // async index({ commit }, params = {}) {
  //   const {
  //     data: { data: organizations, meta },
  //   } = await this.$axios.get(`organizations`, {
  //     params,
  //   })
  //   commit('setOrganizationsList', organizations)
  //   commit('SET_META', meta)
  // },
  // async store({ commit }, payload) {
  //   const {
  //     data: { data: organization },
  //   } = await this.$axios.post(`organizations`, {
  //     data: payload,
  //   })
  //   commit('SET_CURRENT', organization)
  //   this.$router.replace(`/organizations/${organization.id}`)
  // },
  // async update({ commit }, payload) {
  //   return await this.$axios.patch(`configurations/${payload.id}`, {
  //     data: payload,
  //   })
  // },
  // async delete({ commit, state, dispatch }, id) {
  //   try {
  //     await this.$axios.delete(`organizations/${id}`)
  //     commit('REMOVE_ORGANIZATION', id)
  //     dispatch('notifications/success', 'Deleted Successfully', {
  //       root: true,
  //     })
  //   } catch (error) {}
  // },
}

export const state = () => ({
  list: [],
  current: null,
  meta: {
    total: 0,
  },
})

export const mutations = {
  SET_LIST(state, users) {
    state.list = users
  },
  SET_CURRENT(state, user) {
    state.current = user
  },
  SET_META(state, meta) {
    state.meta = meta
  },
  REMOVE_ORGANIZATION(state, id) {
    state.list.splice(
      state.list.findIndex((user) => user.id === id),
      1
    )
  },
}

// export const actions = {
//   async get({ commit }, id) {
//     return await this.$axios.get(`users/${id}`)
//   },
//   async index({ commit }) {
//     const res = await this.$axios.get(`users`)
//     return res
//   },
//   async store({ commit }, user) {
//     const res = await this.$axios.post(`users`, user)
//     return res
//   },
//   async update({ commit }, user) {
//     const res = await this.$axios.put(`users/${user.id}`, user)
//     return res
//   },
// }

export const actions = {
  async getAuthUser({ commit }) {
    const username = this.$auth.user.preferred_username
    const {
      data: { data: user },
    } = await this.$axios.get(`users/${username}`)
    commit('SET_CURRENT', user)
  },
  async get({ commit }, id) {
    const {
      data: { data: user },
    } = await this.$axios.get(`users/${id}`)
    commit('SET_CURRENT', user)
  },
  async listSettings({ commit }, username) {
    const {
      data: { data: settings },
    } = await this.$axios.get(`users/${username}/settings`)
    return settings
  },
  async updateSettings({ commit }, { username, settings: rawSettings }) {
    const settings = Object.values(rawSettings).map((o) => {
      return {
        id: o.id,
        value: o.attributes.value,
      }
    })

    const {
      data: { data: updatedSettings },
    } = await this.$axios.patch(`users/${username}/settings`, {
      data: {
        settings,
      },
    })
    return updatedSettings
  },
  async index({ commit }, params = {}) {
    const {
      data: { data: users, meta },
    } = await this.$axios.get(`users`, {
      params,
    })
    commit('SET_LIST', users)
    commit('SET_META', meta)
  },
  async store({ commit }, payload) {
    const {
      data: { data: user },
    } = await this.$axios.post(`users`, {
      data: payload,
    })
    commit('SET_CURRENT', user)
    this.$router.replace(`/users/${user.id}`)
  },
  async update(
    { commit },
    { username = this.$auth.user.preferred_username, ...attributes }
  ) {
    const {
      data: { data: user },
    } = await this.$axios.patch(`users/${username}`, {
      data: attributes,
    })
    commit('SET_CURRENT', user)
  },
  async delete({ commit, dispatch }, id) {
    try {
      await this.$axios.delete(`users/${id}`)
      commit('REMOVE_USER', id)
      dispatch('notifications/success', 'Deleted Successfully', {
        root: true,
      })
    } catch (error) {}
  },
}

export const state = () => ({
  list: [],
})

export const mutations = {
  SET_LIST(state, countries) {
    state.list = countries
  },
}
export const actions = {
  async getList({ commit, state }, forceRefresh = false) {
    if (state.list.length > 0 && !forceRefresh) {
      return
    }
    try {
      const countries = await this.$axios.$get(
        `${this.$config.COUNTRY_URL}/all?filter=name,flag,nativeName,alpha3Code`
      )
      return commit('SET_LIST', countries)
    } catch (e) {
      console.log(e)
    }
  },
}

export const state = () => ({
  list: [],
})

export const mutations = {
  SET_LIST(state, roles) {
    state.list = roles
  },
}

export const actions = {
  async index({ commit }) {
    const {
      data: { data: roles },
    } = await this.$axios.get(`roles`)
    commit('SET_LIST', roles)
    return roles
  },
}

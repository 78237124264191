import Vue from 'vue'

export const state = () => ({
  selectedUsers: [],
  selectedOrganization: {},
})

export const mutations = {
  SET_ORGANIZATION(state, organization) {
    console.log('\x1b[32;1m%s\x1b[0m  ', '=> SET_ORGANIZATION ', organization)

    state.selectedOrganization = organization
  },
  SET_SELECTED(state, users) {
    state.selectedUsers = users
  },

  // extract id into id and rest into payload
  UPDATE_USER(state, { id, ...payload }) {
    console.log(
      '🚀 ~ file: users-organizations.js ~ line 18 ~ UPDATE_USER ~ payload',
      payload
    )
    const objIndex = state.selectedUsers.findIndex((obj) => obj.id === id)
    Vue.set(
      state.selectedUsers,
      objIndex,
      Object.assign({}, state.selectedUsers[objIndex], payload)
    )
  },
}

export const getters = {
  hasSelectedUsers(state) {
    return !!state.selectedUsers.length
  },
  attachPayload(state, getters) {
    if (!getters.hasSelectedUsers) {
      return []
    }
    return state.selectedUsers.map((user) => {
      return {
        id: user.id,
        data: {
          roles: user.roles,
          informations: {
            email: user.pivot_email || null,
            phone: user.pivot_phone || null,
            company_position: user.pivot_company_position || null,
          },
        },
      }
    })
  },
}

export const actions = {
  async attachMany({ commit, getters, dispatch, state }) {
    const { id: orgId } = state.selectedOrganization
    await this.$axios.post(`/organizations/${orgId}/users`, {
      data: getters.attachPayload,
    })
    commit('SET_SELECTED', [])
    dispatch('notifications/success', 'Attached successfully', {
      root: true,
    })
  },
}

export const state = () => ({
  data: null,
  loading: true,
  error: null,
  currentPage: 1,
})

export const getters = {
  myComputedProperty(state) {
    return null
  },
}
export const mutations = {
  SET_LOADING(state, status) {
    state.loading = status
  },
  SET_ERROR(state, errorMessage) {
    state.error = errorMessage
  },
  SET_CURRENT_PAGE(state, pageNumber) {
    state.currentPage = pageNumber
  },
  SET_DATA(state, data) {
    state.data = data
  },
}

export const actions = {
  async fetchData({ commit, state }, endpoint, page = 1) {
    commit('SET_DATA', null)
    commit('SET_LOADING', true)

    // you cause use axis as an alternative
    return await this.$axios(`${endpoint}?page=${page}`)
      .then((response) => {
        // set the response data
        commit('SET_DATA', response.data)
        commit('SET_CURRENT_PAGE', page)
      })
      .catch((error) => {
        let message = 'An error occured'
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          message = 'An error with the request occured'
        }
        commit('SET_ERROR', message)
      })
      .then(() => {
        // turn off the loading state
        commit('SET_LOADING', false)
      })
  },
}

export const state = () => ({})

export const mutations = {}

export const actions = {
  async index({ commit }, id) {
    const res = await this.$axios.get(`organizations/${id}/systems`)
    return res
  },
  async store({ commit }, { id, systems }) {
    const res = await this.$axios.post(`organizations/${id}/systems`, {
      data: systems,
    })
    return res
  },
  /*eslint-disable*/
  async update({ commit }, { organizationId, systemId, payload }) {
    console.log(
      '🚀 ~ file: organizations-systems.js ~ line 18 ~ update ~ organizationId, systemId, ...payload',
      organizationId,
      systemId,
      payload
    )
    const res = await this.$axios.patch(
      `organizations/${organizationId}/systems/${systemId}`,
      { data: payload }
    )
    return res
  },
  async destroy({ commit }, { organizationId, systemId }) {
    console.log('\x1b[32;1m%s\x1b[0m  ', '=> systemId', systemId)
    const res = await this.$axios.delete(
      `organizations/${organizationId}/systems/${systemId}`
    )
    return res
  },

  async getOrganizationsBySystem({ commit }, id, params) {
    const resp = await this.$axios.get(`systems/${id}/organizations`, {
      params,
    })

    return resp
  },
  async getOrganization({ commit }, params) {
    const resp = await this.$axios.get(
      `systems/${params.id}/organizations/${params.org_id}`
    )
    return resp
  },
}

export default ($axios) => (resource) => ({
  index(params = {}) {
    return $axios.$get(`/${resource}`, { params })
  },

  create(payload) {
    return $axios.$post(`/${resource}`, { data: payload })
  },

  show(id) {
    return $axios.$get(`/${resource}/${id}`)
  },

  update(payload, id) {
    return $axios.$put(`/${resource}/${id}`, { data: payload })
  },

  delete(id) {
    return $axios.$delete(`/${resource}/${id}`)
  },
})

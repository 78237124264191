import createRepository from '~/api/repository.js'

export default ({ $axios }, inject) => {
  const repositoryWithAxios = createRepository($axios)

  const repositories = {
    organizations: repositoryWithAxios('organizations'),
    users: repositoryWithAxios('users'),
    systems: repositoryWithAxios('systems'),
  }
  inject('api', repositories)
}

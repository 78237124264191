export const state = () => ({})

export const mutations = {}

export const actions = {
  async show({ commit }, { id }) {
    return await this.$axios.get(`profiles/${id}`)
  },

  async upsertForUser({ commit }, { username, ...payload }) {
    return await this.$axios.post(`users/${username}/profile`, payload)
  },
  async delete({ commit }, { username, profileId }) {
    return await this.$axios.delete(`users/${username}/profile/${profileId}`)
  },
}

export const state = () => ({
  current: null,
  list: [],
  meta: {
    total: 0,
  },
})
export const getters = {
  getById: (state) => (id) => {
    return state.list.find((org) => org.id === id)
  },
}
export const mutations = {
  REMOVE_ORGANIZATION(state, id) {
    state.list.splice(
      state.list.findIndex((org) => org.id === id),
      1
    )
  },
  setOrganizationsList(state, organizations) {
    state.list = organizations
  },
  SET_CURRENT(state, organization) {
    state.current = organization
  },
  SET_META(state, meta) {
    state.meta = meta
  },
  UPSERT(state, organization) {
    let list = state.list
    const index = list.findIndex((org) => org.id === organization.id)
    if (index === -1) {
      list = [...list, organization]
    } else {
      list[index] = organization
    }
    state.list = list
  },
}

export const actions = {
  async get({ commit }, id) {
    const {
      data: { data: organization },
    } = await this.$axios.get(`organizations/${id}`)
    commit('UPSERT', organization)
    return organization
  },

  async index({ commit }, params = {}) {
    const {
      data: { data: organizations, meta },
    } = await this.$axios.get(`organizations`, {
      params,
    })
    commit('setOrganizationsList', organizations)
    commit('SET_META', meta)
  },
  async store({ commit }, payload) {
    const {
      data: { data: organization },
    } = await this.$axios.post(`organizations`, {
      data: payload,
    })
    commit('SET_CURRENT', organization)
    commit('UPSERT', organization)
    return organization
  },
  async update({ commit }, payload) {
    const {
      data: { data: organization },
    } = await this.$axios.patch(`organizations/${payload.id}`, {
      data: payload,
    })
    commit('UPSERT', organization)
    return organization
  },
  async delete({ commit, state, dispatch }, id) {
    try {
      await this.$axios.delete(`organizations/${id}`)
      commit('REMOVE_ORGANIZATION', id)
      dispatch('notifications/success', 'Deleted Successfully', {
        root: true,
      })
    } catch (error) {}
  },
}
